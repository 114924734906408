import img from "@/asset/page-loading.png"
import './Loading.less'

export default () => {
  return <div className="jump-page-loading">
    <div>
      <img src={img} alt="" />
      <span>加载中...</span>
    </div>
  </div>;
}