import { useState, useEffect } from 'react'

export default function usePopup() {

    const [show,setShow] = useState(false) // 弹窗是否显示
    const [adviceType,setAdviceType] = useState('') // 反馈类型

    return {
        show, setShow, adviceType, setAdviceType
      }
}