import request from '@/tools/fecth';


// 获取企业成员管理列表
export function GetEnterpriseUserList(query:any) {
    return request(`/system/user/list?enterpriseId=${query.enterpriseId}&pageNum=${query.pageNum}&pageSize=${query.pageSize}`,{
        method: 'get',
    });
}

// 获取合作伙伴产品相关数据
export function getCooperationInfo(query:string) {
    return request(`/portalManage/serviceManage/getServiceAttrCount?enterpriseId=` + query,{
        method: 'get',
    });
}

// 消息中心-获取消息列表
export function getMessagesList(query:object) {
    return request( `/portalManage/busMessage/getWorkMessageList`,{
        method: 'post',
        data: query
    });
}

// 消息中心-获取未读消息数
export function getUnreadMsgCount(query:object) {
    return request( `/portalManage/busMessage/getWorkMessageNum`,{
        method: 'post',
        data: query
    });
}

// 消息中心-删除单条消息
export function deleteThisMessage(id:string) {
    return request( `/portalManage/busMessage/${id}`,{
        method: 'delete',
    });
}

// 消息中心-更新消息状态为已读
export function updateMsgStatus(id:string) {
    return request( `/portalManage/busMessage/updateStatus?id=${id}`,{
        method: 'post',
    });
}

// 获取应用列表
export function getApplications(query:string) {
    return request( `/Business_Manage/Bus_Enterprise_AddConfig/GetEnterpriseUrl?WorkConfigType=10&EnterpriseID=${query}`,{
        method: 'post',
        // data: {
        //     prefixUrl: true,
        //     delToken: true
        // }
    });
}

// 获取密文
export function getApplicationToken(query:object) {
    return request( `/Business_Manage/Bus_Enterprise_AddConfig/GetToken`,{
        method: 'post',
        data: query
    });
}

