// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from 'F:/福州市工业互联网/门户平台/node_modules/umi/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/Loading';

export function getRoutes() {
  const routes = [
  {
    "path": "/agreementAddress",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__agreementPage__agreementPage' */'@/pages/agreementPage/agreementPage'), loading: LoadingComponent}),
    "exact": true,
    "title": "协议",
    "search": false
  },
  {
    "path": "/noPermission",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__noPermission__noPermission' */'@/pages/noPermission/noPermission'), loading: LoadingComponent}),
    "exact": true,
    "title": "无权限访问",
    "search": false
  },
  {
    "path": "/autoLogin",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__login-register__login__authLogin' */'@/pages/login-register/login/authLogin'), loading: LoadingComponent}),
    "exact": true,
    "title": "登录",
    "search": false
  },
  {
    "path": "/workbench",
    "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/auth'), loading: LoadingComponent})],
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__index' */'@/layouts/index'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/workbench",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__workbench__enterprise_account__workbench__workbench' */'@/pages/workbench/enterprise_account/workbench/workbench'), loading: LoadingComponent}),
        "exact": true,
        "title": "工作台首页",
        "search": true
      },
      {
        "path": "/workbench/statisticalAnalysis",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__workbench__enterprise_account__workbench__statisticalAnalysis__statisticalAnalysis' */'@/pages/workbench/enterprise_account/workbench/statisticalAnalysis/statisticalAnalysis'), loading: LoadingComponent}),
        "exact": true,
        "title": "统计分析",
        "search": true
      },
      {
        "path": "/workbench/applicationList",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__workbench__enterprise_account__workbench__applicationList__applicationList' */'@/pages/workbench/enterprise_account/workbench/applicationList/applicationList'), loading: LoadingComponent}),
        "exact": true,
        "title": "应用列表",
        "search": true
      },
      {
        "path": "/workbench/changePassword",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__workbench__enterprise_account__changePassword__changePassword' */'@/pages/workbench/enterprise_account/changePassword/changePassword'), loading: LoadingComponent}),
        "exact": true,
        "title": "修改密码",
        "search": true
      },
      {
        "path": "/workbench/productOrdering",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__workbench__enterprise_account__productOrdering__productOrdering' */'@/pages/workbench/enterprise_account/productOrdering/productOrdering'), loading: LoadingComponent}),
        "exact": true,
        "title": "产品订购",
        "search": true
      },
      {
        "path": "/workbench/consulting",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__workbench__enterprise_account__consulting__consulting' */'@/pages/workbench/enterprise_account/consulting/consulting'), loading: LoadingComponent}),
        "exact": true,
        "title": "咨询与投诉",
        "search": true
      },
      {
        "path": "/workbench/notice",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__workbench__enterprise_account__notice__notice' */'@/pages/workbench/enterprise_account/notice/notice'), loading: LoadingComponent}),
        "exact": true,
        "title": "通知公告",
        "search": true
      },
      {
        "path": "/workbench/authenticationHandle",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__workbench__enterprise_account__workbench__authenticationHandle__authentication' */'@/pages/workbench/enterprise_account/workbench/authenticationHandle/authentication'), loading: LoadingComponent}),
        "exact": true,
        "title": "企业认证",
        "search": false
      },
      {
        "path": "/workbench/authenticationPartner",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__workbench__enterprise_account__workbench__authenticationPartner__authenticationPartner' */'@/pages/workbench/enterprise_account/workbench/authenticationPartner/authenticationPartner'), loading: LoadingComponent}),
        "exact": true,
        "title": "合作伙伴认证",
        "search": false
      },
      {
        "path": "/workbench/identification",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__workbench__enterprise_account__identification__identification__identification' */'@/pages/workbench/enterprise_account/identification/identification/identification'), loading: LoadingComponent}),
        "exact": true,
        "title": "二级标识解析",
        "search": true
      },
      {
        "path": "/workbench/identification/shareApplication",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__workbench__enterprise_account__identification__shareApplication__shareApplication' */'@/pages/workbench/enterprise_account/identification/shareApplication/shareApplication'), loading: LoadingComponent}),
        "exact": true,
        "title": "共享申请",
        "search": true
      },
      {
        "path": "/workbench/identification/shareAudit",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__workbench__enterprise_account__identification__shareAudit__shareAudit' */'@/pages/workbench/enterprise_account/identification/shareAudit/shareAudit'), loading: LoadingComponent}),
        "exact": true,
        "title": "共享审核",
        "search": true
      },
      {
        "path": "/workbench/identification/templateManagement",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__workbench__enterprise_account__identification__templateManagement__templateManagement' */'@/pages/workbench/enterprise_account/identification/templateManagement/templateManagement'), loading: LoadingComponent}),
        "exact": true,
        "title": "模板管理",
        "search": true
      },
      {
        "path": "/workbench/identification/identificationManagement",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__workbench__enterprise_account__identification__identificationManagement__identificationManagement' */'@/pages/workbench/enterprise_account/identification/identificationManagement/identificationManagement'), loading: LoadingComponent}),
        "exact": true,
        "title": "标识管理",
        "search": true
      },
      {
        "path": "/workbench/subsidyApply",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__workbench__enterprise_account__subsidyApply__subsidyApply' */'@/pages/workbench/enterprise_account/subsidyApply/subsidyApply'), loading: LoadingComponent}),
        "exact": true,
        "title": "补贴申请"
      },
      {
        "path": "/workbench/memberManagement",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__workbench__enterprise_account__memberManagement__memberManagement' */'@/pages/workbench/enterprise_account/memberManagement/memberManagement'), loading: LoadingComponent}),
        "exact": true,
        "title": "企业成员管理",
        "search": true
      },
      {
        "path": "/workbench/messageCenter",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__workbench__enterprise_account__messageCenter__messageCenter' */'@/pages/workbench/enterprise_account/messageCenter/messageCenter'), loading: LoadingComponent}),
        "exact": true,
        "title": "消息中心",
        "search": true
      },
      {
        "path": "/workbench/informationManagement",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__workbench__enterprise_account__informationManagement__informationManagement' */'@/pages/workbench/enterprise_account/informationManagement/informationManagement'), loading: LoadingComponent}),
        "exact": true,
        "title": "信息管理",
        "search": true
      },
      {
        "path": "/workbench/enterpriseInfo",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__workbench__enterprise_account__workbench__enterpriseInfo__enterpriseInfo' */'@/pages/workbench/enterprise_account/workbench/enterpriseInfo/enterpriseInfo'), loading: LoadingComponent}),
        "exact": true,
        "title": "查看企业信息",
        "search": false
      },
      {
        "path": "/workbench/productManage",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__workbench__cooperation_account__productManage__productManage' */'@/pages/workbench/cooperation_account/productManage/productManage'), loading: LoadingComponent}),
        "exact": true,
        "title": "产品管理",
        "search": true
      },
      {
        "path": "/workbench/releaseProduct",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__workbench__cooperation_account__releaseProduct__releaseProduct' */'@/pages/workbench/cooperation_account/releaseProduct/releaseProduct'), loading: LoadingComponent}),
        "exact": true,
        "title": "发布产品",
        "search": true
      }
    ]
  },
  {
    "path": "/specialAccess",
    "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/specialAuth'), loading: LoadingComponent})],
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__divLayout' */'@/layouts/divLayout'), loading: LoadingComponent}),
    "routes": []
  },
  {
    "path": "/activities",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__divLayout' */'@/layouts/divLayout'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/activities/xingye",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__activities__xingye__xingye' */'@/pages/activities/xingye/xingye'), loading: LoadingComponent}),
        "exact": true,
        "title": "兴业普惠云",
        "search": true
      },
      {
        "path": "/activities/identificationAnalysis",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__activities__eap__identity__identificationAnalysis__identificationAnalysis' */'@/pages/activities/eap/identity/identificationAnalysis/identificationAnalysis'), loading: LoadingComponent}),
        "exact": true,
        "title": "标识解析"
      },
      {
        "path": "/activities/identificationAnalysisJJ",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__activities__eap__identity__identificationAnalysisJJ__identificationAnalysis' */'@/pages/activities/eap/identity/identificationAnalysisJJ/identificationAnalysis'), loading: LoadingComponent}),
        "exact": true,
        "title": "标识解析"
      },
      {
        "path": "/activities/identificationAnalysisZZ",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__activities__eap__identity__identificationAnalysisZZ__identificationAnalysis' */'@/pages/activities/eap/identity/identificationAnalysisZZ/identificationAnalysis'), loading: LoadingComponent}),
        "exact": true,
        "title": "标识解析"
      },
      {
        "path": "/activities/identityQuery",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__activities__eap__identity__identityQuery__identityQuery' */'@/pages/activities/eap/identity/identityQuery/identityQuery'), loading: LoadingComponent}),
        "exact": true,
        "title": "标识查询"
      },
      {
        "path": "/activities/identityQueryJJ",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__activities__eap__identity__identityQueryJJ__identityQuery' */'@/pages/activities/eap/identity/identityQueryJJ/identityQuery'), loading: LoadingComponent}),
        "exact": true,
        "title": "标识查询"
      },
      {
        "path": "/activities/identityQueryZZ",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__activities__eap__identity__identityQueryZZ__identityQuery' */'@/pages/activities/eap/identity/identityQueryZZ/identityQuery'), loading: LoadingComponent}),
        "exact": true,
        "title": "标识查询"
      },
      {
        "path": "/activities/industryInformation",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__activities__eap__industryInformation__industryInformation' */'@/pages/activities/eap/industryInformation/industryInformation'), loading: LoadingComponent}),
        "exact": true,
        "title": "政策资讯"
      },
      {
        "path": "/activities/policyMatching",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__activities__eap__industryInformation__policyMatching__policyMatching' */'@/pages/activities/eap/industryInformation/policyMatching/policyMatching'), loading: LoadingComponent}),
        "exact": true,
        "title": "政策速配"
      },
      {
        "path": "/activities/policyMatchingNew",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__activities__eap__industryInformation__policyMatchingNew__policyMatching' */'@/pages/activities/eap/industryInformation/policyMatchingNew/policyMatching'), loading: LoadingComponent}),
        "exact": true,
        "title": "政策速配"
      },
      {
        "path": "/activities/industryResultList",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__activities__eap__industryInformation__industryResultList__industryResultList' */'@/pages/activities/eap/industryInformation/industryResultList/industryResultList'), loading: LoadingComponent}),
        "exact": true,
        "title": "政策搜索列表"
      },
      {
        "path": "/activities/industryResultListNew",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__activities__eap__industryInformation__industryResultListNew__industryResultList' */'@/pages/activities/eap/industryInformation/industryResultListNew/industryResultList'), loading: LoadingComponent}),
        "exact": true,
        "title": "政策搜索列表"
      },
      {
        "path": "/activities/industryResultDetail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__activities__eap__industryInformation__industryResultDetail__industryResultDetail' */'@/pages/activities/eap/industryInformation/industryResultDetail/industryResultDetail'), loading: LoadingComponent}),
        "exact": true,
        "title": "政策搜索结果"
      },
      {
        "path": "/activities/government/newsNoticeDetails",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__activities__eap__government__news-notice-details__news-notice-details' */'@/pages/activities/eap/government/news-notice-details/news-notice-details'), loading: LoadingComponent}),
        "exact": true,
        "title": "新闻详情"
      }
    ]
  },
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__index' */'@/layouts/index'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/",
        "redirect": "/index",
        "title": "首页",
        "bannerHeaderBG": true,
        "exact": true
      },
      {
        "path": "/index",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__index__index' */'@/pages/index/index'), loading: LoadingComponent}),
        "exact": true,
        "title": "福州市工业互联网平台",
        "search": true,
        "bannerHeaderBG": true
      },
      {
        "path": "/login",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__login-register__login__login' */'@/pages/login-register/login/login'), loading: LoadingComponent}),
        "exact": true,
        "title": "登录",
        "search": true,
        "bannerHeaderBG": true
      },
      {
        "path": "/register",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__login-register__register__register' */'@/pages/login-register/register/register'), loading: LoadingComponent}),
        "exact": true,
        "title": "注册",
        "search": true,
        "bannerHeaderBG": true
      },
      {
        "path": "/forget",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__login-register__forget__forget' */'@/pages/login-register/forget/forget'), loading: LoadingComponent}),
        "exact": true,
        "title": "忘记密码",
        "search": true,
        "bannerHeaderBG": true
      },
      {
        "path": "/productMarket",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__productMarket__index__index' */'@/pages/productMarket/index/index'), loading: LoadingComponent}),
        "exact": true,
        "title": "产品市场",
        "search": true,
        "bannerHeaderBG": true
      },
      {
        "path": "/productList",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__productMarket__productList__productList' */'@/pages/productMarket/productList/productList'), loading: LoadingComponent}),
        "exact": true,
        "title": "产品列表",
        "search": true,
        "bannerHeaderBG": true
      },
      {
        "path": "/productDetail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__productMarket__productDetail__productDetail' */'@/pages/productMarket/productDetail/productDetail'), loading: LoadingComponent}),
        "exact": true,
        "title": "产品详情",
        "search": false,
        "bannerHeaderBG": true
      },
      {
        "path": "/solution",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__solution__solution' */'@/pages/solution/solution'), loading: LoadingComponent}),
        "exact": true,
        "title": "解决方案",
        "search": true,
        "bannerHeaderBG": true
      },
      {
        "path": "/solution/details",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__solution__solutionDetail__solutionDetail' */'@/pages/solution/solutionDetail/solutionDetail'), loading: LoadingComponent}),
        "exact": true,
        "title": "解决方案详情",
        "search": true,
        "bannerHeaderBG": false
      },
      {
        "path": "/government/newsNoticeDetails",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__government__news-notice-details__news-notice-details' */'@/pages/government/news-notice-details/news-notice-details'), loading: LoadingComponent}),
        "exact": true,
        "title": "新闻详情",
        "search": false,
        "bannerHeaderBG": false
      },
      {
        "path": "/aboutUs",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__aboutUs__aboutUs' */'@/pages/aboutUs/aboutUs'), loading: LoadingComponent}),
        "exact": true,
        "title": "关于我们",
        "search": true,
        "bannerHeaderBG": true
      },
      {
        "path": "/search",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__search__search' */'@/pages/search/search'), loading: LoadingComponent}),
        "exact": true,
        "title": "搜索",
        "search": true,
        "bannerHeaderBG": false
      },
      {
        "path": "/industryInformation",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__industryInformation__industryInformation' */'@/pages/industryInformation/industryInformation'), loading: LoadingComponent}),
        "exact": true,
        "title": "政策资讯",
        "search": true,
        "bannerHeaderBG": true
      },
      {
        "path": "/policyMatching",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__industryInformation__policyMatching__policyMatching' */'@/pages/industryInformation/policyMatching/policyMatching'), loading: LoadingComponent}),
        "exact": true,
        "title": "政策速配",
        "search": true,
        "bannerHeaderBG": true
      },
      {
        "path": "/industryResultList",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__industryInformation__industryResultList__industryResultList' */'@/pages/industryInformation/industryResultList/industryResultList'), loading: LoadingComponent}),
        "exact": true,
        "title": "政策搜索列表",
        "search": true,
        "bannerHeaderBG": true
      },
      {
        "path": "/industryResultDetail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__industryInformation__industryResultDetail__industryResultDetail' */'@/pages/industryInformation/industryResultDetail/industryResultDetail'), loading: LoadingComponent}),
        "exact": true,
        "title": "政策搜索结果",
        "search": true,
        "bannerHeaderBG": false
      },
      {
        "path": "/industrialServices",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__industrialServices__industrialServices' */'@/pages/industrialServices/industrialServices'), loading: LoadingComponent}),
        "exact": true,
        "title": "产业服务",
        "search": true,
        "bannerHeaderBG": true
      },
      {
        "path": "/ecologicalPartners",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ecologicalPartners__ecologicalPartners' */'@/pages/ecologicalPartners/ecologicalPartners'), loading: LoadingComponent}),
        "exact": true,
        "title": "生态合作",
        "search": true,
        "bannerHeaderBG": true
      },
      {
        "path": "/identificationAnalysis",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__identity__identificationAnalysis__identificationAnalysis' */'@/pages/identity/identificationAnalysis/identificationAnalysis'), loading: LoadingComponent}),
        "exact": true,
        "title": "标识解析",
        "search": true,
        "bannerHeaderBG": true
      },
      {
        "path": "/identityQuery",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__identity__identityQuery__identityQuery' */'@/pages/identity/identityQuery/identityQuery'), loading: LoadingComponent}),
        "exact": true,
        "title": "标识查询",
        "search": false,
        "bannerHeaderBG": true
      },
      {
        "path": "/developerCommunity",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__developerCommunity__developerCommunity' */'@/pages/developerCommunity/developerCommunity'), loading: LoadingComponent}),
        "exact": true,
        "title": "开发者社区",
        "search": false,
        "bannerHeaderBG": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
