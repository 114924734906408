import request from '../tools/fecth';

// 获取用户信息
export function getOperatorInfo() {
    return request(`/getInfo`,{
        method: 'get',
    });
}

// 获取访客 token(暂不能使用)
export function getGuestOperatorInfo() {
    return request(`/Base_Manage/Home/GetGuestOperatorInfo`,{
        method: 'post',
        data: {}
    });
}

// 企业ID获取企业信息
export function getEnterpriseInfo(id:string) {
    return request(`/Business_Manage/Bus_Enterprise/id/${id}`,{
        method: 'get'
    });
}

//登录验证码
export function getCaptchaImage() {
    return request(`/captchaImage`,{
        method: 'get',
        data:{
            delToken: true
        }
    });
}

// 退出登录
export function logOut() {
    return request(`/logout`,{
        method: 'post',
    });
}

//省类表
export function getAreaTreeModelsUp(areaid:any) {
    return request(`/businessManage/city/getCityList/${areaid}`,{
        method: 'get',
    });
}

//某个省ID：获取这个省下的所有城市
export function getAreaTreeProvince(query:Object) {
    return request(`/Base_Manage/Base_Area/GetAreaTreeProvince`,{
        method: 'post',
        data:query
    });
}

//获取某个区县下所有的乡镇街道信息
export function getAreaTreeStree(query:Object) {
    return request("/Base_Manage/Base_Area/GetAreaTreeStree",{
        method: 'post',
        data:query
    });
}

//根据区域ID，获取省份+市+区县信息
export function getAreaInfo(areaid:any) {
    return request(`/businessManage/city/${areaid}`,{
        method: 'get',
    });
}

// 标识解析查询
export function queryIdentityvByHandle(query:Object) {
    return request( `/register/identityNew/queryIdentityvByHandle`,{
        method: 'post',
        data: query
        // data: {
        //     prefixUrl: true,
        //     delToken: true
        // }
    });
}

export function queryIdentityvByHandleNew(query:Object) {
  return fetch(`${window.configUrl().reqDomain.FZBiz}/sn/${query.handle}`, {
    method: "GET",
  }).then((res) => res.json())

}


// 晋江标识解析查询
export function queryIdentityByHandleJJ(query: String) {
  return fetch(`${window.configUrl().reqDomain.JJBiz}/qz/sign/getIdSign?handle=${query}`, {
    method: "GET",
  }).then((res) => res.json())
}
// 漳州标识解析查询
export function queryIdentityByHandleZZ(query: String) {
  return fetch(`${window.configUrl().reqDomain.ZZBiz}/sn/${query}`, {
    method: "GET",
  }).then((res) => res.json())
}

// 获取首页banner
export function getShowBannerList() {
    return request(`/portalManage/busBanner/getShowBannerList/01`,{
        method: 'get',
    });
}

//获取行业类目
export function getItemDetailList(query:Object) {
    return request(`/System_Manage/System_DataItemDetail/GetItemDetailList`,{
        method: 'post',
        data: query
    })
}

// footer底部信息获取
export function getFooterInfo() {
    return request(`/portalManage/busWebsiteinfo/selectFirstElement`,{
        method: 'get',
        data: {
            delToken: true
        }
    })
}

// 查询所有企业的列表 合作伙伴列表
export function getEnterpriseList(query:Object) {
    return request(`/Business_Manage/Bus_Enterprise/GetMoreDataList`,{
        method: 'post',
        data: query
    })
}

// 企业名录-所属区域查询列表
export function getAreaList() {
    return request(`/Base_Manage/Bus_Area/list`,{
        method: 'get',
        data: {
            delToken: true
        }
    })
}

// 查询单个企业详细信息
export function getEnterpriseDetail(id:string) {
    return request(`/Business_Manage/Bus_Enterprise/id/${id}`,{
        method: 'get',
    })
}

// 获取问卷
export function getQuestion() {
    return request(`/portalManage/questionnaire/getQuestion`,{
        method: 'get',
    })
}

// 提交问卷
export function submitQuestionresult(query:Object) {
    return request(`/portalManage/questionresult`,{
        method: 'post',
        data: query
    })
}

// 获取搜索结果数量
export function getSelectSearchNum(query:string) {
    return request(`/portalManage/baseNews/selectSearchNum?keyWord=${query}`,{
        method: 'post',
    })
}

// 获取搜索结果列表
export function getSelectSearchAll(query:Object) {
    return request(`/portalManage/baseNews/selectSearchAll`,{
        method: 'post',
        data: query
    })
}

// 根据字典类型查询字典数据信息
export function getDicts(dictType:string) {
    return request('/system/dict/data/type/' + dictType, {
      method: 'get'
    })
}

// 根据字典类型查询字典数据信息
export function getItemName(query:any) {
    return request('/system/dict/data/selectDicData', {
      method: 'post',
      data: query
    })
}

// 二级标识
export function getIdentityToken(username:string) {
    return request('/register/identityNew/getToken?username=' + username, {
      method: 'get'
    })
}

// 首页第一块 数据维护
export function getIndexData() {
    return request('/portalManage/homepage/getEnabled', {
      method: 'get'
    })
}

// 获取优惠券的 NO
export function postFlowNo(query:Object) {
    return request("/businessManage/applySubsidy/getFlowNo",{
        method: 'post',
        data:query
    });
}

// 批量获取优惠券的 NO
export function postFlowsNo(query:Array<string>) {
    return request("/businessManage/applySubsidy/getFlowsNo",{
        method: 'post',
        data:query
    });
}

// 获取企业详细信息
export function getEnterpriseInfoDetails(enterpriseId:string) {
    return request(`/businessManage/enterpriseAttribute/${enterpriseId}`,{
        method: 'get',
    });
}

// 新增企业详细信息
export function AddNewEnInfoDetails(query:any) {
    return request(`/businessManage/enterpriseAttribute`,{
        method: 'post',
        data:query
    });
}

// 修改企业详细信息
export function EditNewEnInfoDetails(query:any) {
    return request(`/businessManage/enterpriseAttribute`,{
        method: 'put',
        data:query
    });
}
