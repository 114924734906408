import request, { extend } from 'umi-request';
import { history } from 'umi';

import { notification,Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons'
const { confirm } = Modal

import { getToken, removeToken } from "@/tools/auth"

const reqDomain = process.env.UMI_URL?process.env.UMI_URL:window.configUrl().reqDomain['apiport'];
// console.log(reqDomain);

// interface CodeMessage { [key:number]: string }
const systemCodeObj: number = 40
const codeMessage: any = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。'
}
// type codeMessage:{[key:number]: string}
/**
 * 异常处理程序
 */

const errorHandler = (error: { response: Response }): Response => {

  const { response } = error;

  if (response && response.status) {
    const errorText = codeMessage[response.status] || response.statusText;
    const { status, url } = response;
    notification.error({
      message: `请求错误 ${status}: ${url}`,
      description: errorText,
    });
  } else if (!response) {
    notification.error({
      description: '您的网络发生异常，无法连接服务器',
      message: '网络异常',
    });
  }
  
  return response;
};

/**
 * 配置request请求时的默认参数
 */
const requests = extend({
  errorHandler,
  // 默认错误处理
  // 'credentials' 发送带凭据的请求
  // 为了让浏览器发送包含凭据的请求（即使是跨域源），需要设置 credentials: 'include'
  // 如果只想在请求URL与调用脚本位于同一起源处时发送凭据，请添加credentials: 'same-origin'
  // 要改为确保浏览器不在请求中包含凭据，请使用credentials: 'omit'
  credentials: 'same-origin',
  timeout: 10000, //指定请求超时的毫秒数（0 表示无超时时间）
});



// request拦截器, 改变url 或 options.
/**
 * 额外参数说明
 * prefixUrl：使用自定义请求前缀
 * delToken：接口删除token
 * passTokeny：未登录时，是否传 token【nobody】
 */

request.interceptors.request.use((url: string, options) => {
  let Authorization = getToken()
  options.data = options.data ?? {}
  options.data.systemCode = systemCodeObj
  const completeUrl = options.data.prefixUrl ? url : reqDomain + url
  // const completeUrl = options.data.prefixUrl ? url : 'http://localhost:20300' + url
  if (options.data.prefixUrl) {
    delete options.data.prefixUrl
  }
  if (Authorization && Authorization !== "nobody") {
    let headers = {}
    if (options.data.delToken) {
      headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }
      delete options.data.delToken
    } else {
      headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': "Bearer " + Authorization,
      }
    }
    return (
      {
        url: completeUrl,
        options: { ...options, headers: headers }
      }
    );
  } else {
    let headers = {}
    // debugger
    if( options.data.passTokeny ){
      headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': "Bearer " + Authorization,
      }
    }
    return (
      {
        url: completeUrl,
        options: { ...options, headers: headers },
      }
    );
  }

})

// response拦截器, 处理response
request.interceptors.response.use(async (response, options) => {
  const dataType = await response.clone().blob();
  if (dataType.type == 'application/json') {
    const data = await response.clone().json()

    if (data.code && data.code !== 200 && data.code !== "200") {
      if (data.code == 401) {  //token过期
        Modal.destroyAll()
        confirm({
          title: '登录已过期，是否重新登录',
          content: `${data.msg}`,
          okText: "去登录",
          cancelText: "取消",
          onOk() {
            removeToken()
            history.push(`/login?goRouter=${history.location.pathname}`)
          },
          onCancel() {},
        })
      } else {
        notification.error({
          message: `错误码 ${data.code}: ${data.msg}`,
          description: "返回值错误",
        })
      }
    }

    if (data.Success == false) {
      if (data.ErrorCode == 401) {  //token过期
        Modal.destroyAll()
        confirm({
          title: '登录已过期，是否重新登录',
          content: `${data.Msg}`,
          okText: "去登录",
          cancelText: "取消",
          onOk() {
            removeToken()
            history.push(`/login?goRouter=${history.location.pathname}`)
          },
          onCancel() {},
        })
      } else {
        notification.error({
          message: `错误码 ${data.ErrorCode}: ${data.Msg}`,
          description: "返回值错误",
        })
      }
    }
  }

  // if( data == null ){
  //   notification.warning({
  //     message: `接口无返回值:${response.url}`,
  //     description: "返回值错误",
  //   })
  // }
  return response;
});


export default requests;
