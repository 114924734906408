import { useState, useCallback, useEffect } from 'react'
import { getUnreadMsgCount } from '@/services/workbench'
import { useModel } from 'umi';

export default function useNotice() {

    const [unreadMsgCount, setUnreadMsgCount] = useState(0)

    const { userInfo } = useModel('useUserInfoModel', model => ({ userInfo: model.userInfo }))

    useEffect(() => {
        if (userInfo) {
          getMsgCount()
        }
      }, [userInfo])

    const getMsgCount = () => {
        // console.log(111)
        let submitobj = {
          enterpriseId: userInfo.EnterpriseID,
          userId: userInfo.Id,
          enterpriseType: userInfo.EnterpriseType
        }
        getUnreadMsgCount(submitobj).then(res => {
          if (res && res.success) {
            setUnreadMsgCount(res.data.num)
          }
        })
    }

    return {
        unreadMsgCount, getMsgCount
      }
}