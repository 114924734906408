import { useEffect, useState } from 'react'

export default function useDeclare() {
    const [companyName, setCompanyName] = useState('') // 企业名称
    const [companyId, setCompanyId] = useState('') // 企业id
    const [establishmentTime, setEstablishmentTime] = useState('') // 成立时间
    const [companyInd, setCompanyInd] = useState('') // 所属行业
    const [companyAddress, setCompanyAddress] = useState('') // 注册地区

    const [resultData, setResultData] = useState('')

    useEffect(() => {
        getSessionObj()
    },[])

    const getSessionObj = () => {
        let str:any = localStorage.getItem('company')
        if(str){
            let obj = JSON.parse(str)
            setCompanyDetails(obj)

            return obj
        }
    }

    const setCompanyDetails = (obj:any) => {
        setCompanyName(obj.companyName?obj.companyName:'')
        setCompanyId(obj.companyId?obj.companyId:'')
        setEstablishmentTime(obj.establishmentTime?obj.establishmentTime:'')
        setCompanyInd(obj.companyInd?obj.companyInd:'')
        setCompanyAddress(obj.companyAddress?obj.companyAddress:'')
        setResultData(obj.resultData ? obj.resultData : {})

        let sessionobj:any = {
            companyName: obj.companyName || '',
            companyId: obj.companyId || '',
            establishmentTime: obj.establishmentTime || '',
            companyInd: obj.companyInd || '',
            companyAddress: obj.companyAddress || '',
            resultData: obj.resultData || {}
        }

        localStorage.setItem('company', JSON.stringify(sessionobj))
    }

    const getCompanyDetails = () => {
        let obj:any = getSessionObj()
        return obj
    }

    return {
        setCompanyDetails, getCompanyDetails
    }
}
